<template>
    <div>
      <div class="table_sty">
        <div class="froms">
          <p class="describe">筛选</p>
          <div class="segmentation"></div>
          <div class="from_submit">
            <el-form label-width="120px" :model="formLabelAlign" class="from">
              <el-form-item label="名称：">
                <el-input
                  placeholder="请输入姓名" 
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.username"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="search_button">
              <div>
                <el-button
                  @click="way_tableData(true)"
                  type="primary"
                  icon="el-icon-search"
                  >查询</el-button
                >
                <el-button @click="on_reset" icon="el-icon-refresh-left"
                  >重置</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="tab_sty">
          <div class="tab_top">
            <p>
              <span class="span1">一共{{ total }}个记录</span>
              <span class="span2">已选择 {{ more_List.length }} 个记录</span>
              <i @click="way_tableData" class="span_i el-icon-refresh"
                >数据更新</i
              >
            </p>
          </div>
          <div>
            <el-table
              highlight-current-row
              @current-change="way_singleselect"
              @selection-change="way_moreselect"
              :data="table_List"
              :header-cell-style="{ background: '#f8f8f9' }"
              style="flex: 1"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="handover" label="交接时间"></el-table-column>
              <el-table-column prop="handoverPla" label="交接地点"></el-table-column>
              <el-table-column prop="pic" label="图片">
                <template slot-scope="scope">
                    <el-image
                    style="width: 100px; height: 100px;object-fit: cover;"
                    :src="scope.row.pic[0]"
                    :preview-src-list="scope.row.pic"
                    ></el-image>
                </template>
              </el-table-column>
              <el-table-column label="交接物品">
                <template slot-scope="scope">
                  <el-link
                    class="list_sty"
                    type="primary"
                    @click="on_particulars(scope.row)"
                    >查看物品</el-link
                  >
                </template>
              </el-table-column>
              <el-table-column prop="addtime" label="创建时间"></el-table-column>
              <!-- <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-link
                    class="list_sty"
                    type="primary"
                    @click="on_particulars(scope.row)"
                    >查看更多</el-link
                  >
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <div class="paging">
            <el-pagination
              @current-change="way_page"
              :page-size="page_size"
              layout="prev, pager, next, jumper"
              :total="total"
              :current-page="page"
            >
            </el-pagination>
          </div>
        </div>
        <el-dialog
          title="交接物品情况"
          :visible.sync="dialogVisible"
          width="50%"
          :before-close="handleClose">
          <div>
              <el-table
                highlight-current-row
                @current-change="way_singleselect"
                @selection-change="way_moreselect"
                border
                :data="details.handoverCon"
                :header-cell-style="{ background: '#f8f8f9' }"
                style="flex: 1"
              >
              <el-table-column prop="title" label="设备名称"></el-table-column>
              <el-table-column prop="value" label="是否异常">
                <template slot-scope="scope">
                  <el-tag size="mini" type="danger" v-if="scope.row.value==0">异常</el-tag>
                  <el-tag size="mini" v-if="scope.row.value==1">正常</el-tag>
                </template>
              </el-table-column>
              </el-table>
            </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </template>
  
  <script>
  import table_minin from "../../assets/minin/table_minin";
  import {
    commutingList,
  } from "../../assets/request/api";
  export default {
    mixins: [table_minin],
    data() {
      return {
        dialogVisible:false,
        formLabelAlign: {
          username: ""
        },
        details:{},
        gridData:[]
      };
    },
    created() {
      this.url = commutingList;
      this.fromData = this.formLabelAlign;
      this.xl_attribute = "type_list";
      this.way_tableData();
    },
    methods: {
      way_extend(data) {
      },
      //详情弹框
      on_particulars(row) {
        this.dialogVisible=true
        this.details=row
        this.gridData=row.guardian
      },
      handleClose(){
        this.dialogVisible=false
      }
    },
  };
  </script>
  <style lang="less" scoped>
  .describe {
    display: inline-block;
    position: relative;
    padding: 4px;
    font-size: 16px;
    color: #4f7afd;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .describe:before {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 50%;
    height: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4f7afd;
  }
  .from {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .form-inp {
    width: 200px;
  }
  .paging {
    text-align: center;
    padding: 10px;
  }
  .tab_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    button {
      margin-right: 10px;
    }
    p {
      display: flex;
      align-items: center;
    }
  }
  .span1 {
    font-size: 14px;
    color: #000000;
  }
  .span2 {
    font-size: 14px;
    margin-left: 10px;
    color: #797979;
  }
  .span_i {
    font-size: 14px;
    color: #797979;
    margin-left: 10px;
    cursor: pointer;
  }
  .el-divider__text {
    color: #0080ff;
    font-weight: bold;
  }
  .segmentation {
    height: 1px;
    background-color: #f4f4f4;
    margin-bottom: 20px;
  }
  .list_sty {
    margin-right: 10px;
  }
  .search_button {
    display: flex;
    margin-bottom: 18px;
    flex-direction: column-reverse;
  }
  .title{
    font-size: 16px;
    font-weight: bold;
    margin:10px 0 20px;
    color: #333;
  }
  .boxs{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 50%;
      display: flex;
      margin-bottom: 20px;
      .lable{
        white-space: nowrap;
      }
    }
  }
  
  ::v-deep .el-dialog{
    overflow-y: scroll;
    max-height: 550px;
    min-width: none;
    &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 4px;
      }
      &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(0, 0, 0, 0.2);
      }
      .block{
          margin-right: 10px;
      }
  }
  </style>
  